export default defineI18nConfig(() => ({
  pluralRules: {
    ru: (choice, _choicesLength) => {
      const value = Math.abs(choice) % 100;
      const num = value % 10;

      if (value > 10 && value < 20) {
        return 2;
      }
      if (num > 1 && num < 5) {
        return 1;
      }
      if (num == 1) {
        return 0;
      }

      return 2;
    },
  },
}));
